import React from "react";
import { Col, Row } from "reactstrap";

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="footer-content">
        <div className="container-fluid">
          <Row className="footer-row">
            <Col xxs="12" sm="6">
              <p className="mb-0 text-muted">© BlueBitLine, 2021</p>
            </Col>
            <Col className="col-sm-6 d-none d-sm-block">
              <a
                className="btn-link float-right"
                href="https://www.bluebitline.com"
              >
                www.bluebitline.com
              </a>
            </Col>
          </Row>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
