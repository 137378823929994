import React from "react";
import { withTranslation } from "react-i18next";
import { Row } from "reactstrap";
import { isMobile } from "../../constants/constants";

import newsData from "../../constants/news.json";

const News = ({ t }) => {
  return (
    <div className={`${isMobile ? "" : "home-content-background"} height-full`}>
      <div
        className={`home-content height-full ${isMobile ? "width-full" : ""}`}
      >
        <Row className="col-12 mb-2">
          <div className="col-12">
            {isMobile ? (
              <h3 className="">{t("Novosti:")}</h3>
            ) : (
              <h1 className="">{t("Novosti:")}</h1>
            )}
          </div>
          {newsData.data.map((news) => (
            <div className="w-100" key={news.title}>
              {news.ID ? (
                <a
                  className="link-button"
                  style={{ margin: "0px" }}
                  href={`/news/${news.ID}`}
                >
                  <ul>
                    <li>
                      <p>{t(news.title)}</p>
                    </li>
                  </ul>
                </a>
              ) : (
                <ul>
                  <li>
                    <p>{t(news.title)}</p>
                  </li>
                </ul>
              )}
            </div>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(News);
