import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./lang/i18n";
import Footer from "./view/navigation/Footer";
import Topnav from "./view/navigation/Topnav";

import english from "./lang/en.json";
import croatian from "./lang/hr.json";
import News from "./view/screens/news";
import NewsSingle from "./view/screens/news-single";

const Home = React.lazy(() =>
  import(/* webpackChunkName: "views-app" */ "./view/screens/home")
);
const Contact = React.lazy(() =>
  import(/* webpackChunkName: "views-user" */ "./view/screens/contact")
);
const Jobs = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ "./view/screens/jobs")
);
const References = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ "./view/screens/references")
);
const Reference = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ "./view/screens/reference")
);
const Product = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ "./view/screens/product")
);
const Products = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ "./view/screens/products")
);
const Certificates = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ "./view/screens/certificates")
);
const EUProjects = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ "./view/screens/eu-projects")
);
const Info = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ "./view/screens/info")
);

const App = () => {
  Object.keys(croatian).forEach((key) => {
    if (!english[key]) console.log(key);
  });
  return (
    <div className="h-100">
      <div id="app-container">
        <Suspense fallback={<div className="loading" />}>
          <Topnav />
          <main>
            <div className="container-fluid ">
              <Suspense fallback={<div className="loading" />}>
                <Router>
                  <Switch>
                    <Route
                      path="/contact"
                      exact
                      render={(props) => <Contact {...props} />}
                    />
                    <Route
                      path="/jobs"
                      exact
                      render={(props) => <Jobs {...props} />}
                    />
                    <Route
                      path="/reference/:ID"
                      exact
                      render={(props) => <Reference {...props} />}
                    />
                    <Route
                      path="/references"
                      exact
                      render={(props) => <References {...props} />}
                    />
                    <Route
                      path="/product/:ID"
                      exact
                      render={(props) => <Product {...props} />}
                    />
                    <Route
                      path="/products"
                      exact
                      render={(props) => <Products {...props} />}
                    />
                    <Route
                      path="/certificates"
                      exact
                      render={(props) => <Certificates {...props} />}
                    />
                    <Route
                      path="/info"
                      exact
                      render={(props) => <Info {...props} />}
                    />
                    <Route
                      path="/home"
                      exact
                      render={(props) => <Home {...props} />}
                    />
                    <Route
                      path="/eu-projects"
                      exact
                      render={(props) => <EUProjects {...props} />}
                    />
                    <Route
                      path="/news/:ID"
                      exact
                      render={(props) => <NewsSingle {...props} />}
                    />
                    <Route
                      path="/news"
                      exact
                      render={(props) => <News {...props} />}
                    />

                    <Route
                      path="/kontakt"
                      exact
                      render={(props) => <Contact {...props} />}
                    />
                    <Route
                      path="/poslovi"
                      exact
                      render={(props) => <Jobs {...props} />}
                    />
                    <Route
                      path="/referenca/:ID"
                      exact
                      render={(props) => <Reference {...props} />}
                    />
                    <Route
                      path="/reference"
                      exact
                      render={(props) => <References {...props} />}
                    />
                    <Route
                      path="/proizvod/:ID"
                      exact
                      render={(props) => <Product {...props} />}
                    />
                    <Route
                      path="/proizvodi"
                      exact
                      render={(props) => <Products {...props} />}
                    />
                    <Route
                      path="/certifikati"
                      exact
                      render={(props) => <Certificates {...props} />}
                    />
                    <Route
                      path="/informacije"
                      exact
                      render={(props) => <Info {...props} />}
                    />
                    <Route
                      path="/eu-projekti"
                      exact
                      render={(props) => <EUProjects {...props} />}
                    />
                    <Route
                      path="/novosti"
                      exact
                      render={(props) => <News {...props} />}
                    />
                    <Route
                      path="/novost/:ID"
                      exact
                      render={(props) => <NewsSingle {...props} />}
                    />

                    <Redirect exact from="/" to={"/home"} />
                    <Redirect to="/error" />
                  </Switch>
                </Router>
              </Suspense>
            </div>
          </main>
          <Footer />
        </Suspense>
      </div>
    </div>
  );
};

export default App;
