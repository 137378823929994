import React from "react";
import { withTranslation } from "react-i18next";
import { Row } from "reactstrap";
import { isMobile } from "../../../constants/constants";

import PozivNaSkupštinu from "../../../assets/docs/News/PozivNaSkupštinu/PozivNaSkupštinu.pdf";
import Zapisnik from "../../../assets/docs/News/PozivNaSkupštinu/Zapisnik.pdf";
import GFI from "../../../assets/docs/News/PozivNaSkupštinu/GFI.pdf";
import IzvješćeNadzornogOdbora from "../../../assets/docs/News/PozivNaSkupštinu/IzvješćeNadzornogOdbora.pdf";
import IzvješćeUprave from "../../../assets/docs/News/PozivNaSkupštinu/IzvješćeUprave.pdf";
import IzvješćeRevizora from "../../../assets/docs/News/PozivNaSkupštinu/IzvješćeRevizora.pdf";
import OdlukaNO from "../../../assets/docs/News/PozivNaSkupštinu/OdlukaNO.pdf";
import OdlukaNO2 from "../../../assets/docs/News/PozivNaSkupštinu/OdlukaNO2.pdf";
import OdlukaOUporabiDobiti from "../../../assets/docs/News/PozivNaSkupštinu/OdlukaOUporabiDobiti.pdf";
import OdlukaOUtvrđivanjuFinancijskihIzvješća from "../../../assets/docs/News/PozivNaSkupštinu/OdlukaOUtvrđivanjuFinancijskihIzvješća.pdf";

const PozivNaSkupštinuNews = ({ t }) => {
  return (
    <>
      <div className="col-12">
        {isMobile ? (
          <h3 className="col-12">{t("Poziv na skupštinu")}</h3>
        ) : (
          <h1 className="col-12">{t("Poziv na skupštinu")}</h1>
        )}
      </div>

      <Row className="col-12">
        <a href={PozivNaSkupštinu} target="_blank" className="">
          <ul>
            <li>
              <p>{t("Poziv na skupštinu")}</p>
            </li>
          </ul>
        </a>
      </Row>

      <Row className="col-12">
        <a href={Zapisnik} target="_blank" className="">
          <ul>
            <li>
              <p>{t("Zapisnik s 1. sjednice nadzornog odbora")}</p>
            </li>
          </ul>
        </a>
      </Row>

      <Row className="col-12">
        <a href={GFI} target="_blank" className="">
          <ul>
            <li>
              <p>{t("GFI")}</p>
            </li>
          </ul>
        </a>
      </Row>

      <Row className="col-12">
        <a href={IzvješćeNadzornogOdbora} target="_blank" className="">
          <ul>
            <li>
              <p>{t("Izvješće nadzornog odbora")}</p>
            </li>
          </ul>
        </a>
      </Row>

      <Row className="col-12">
        <a href={IzvješćeUprave} target="_blank" className="">
          <ul>
            <li>
              <p>{t("Izvješće Uprave")}</p>
            </li>
          </ul>
        </a>
      </Row>

      <Row className="col-12">
        <a href={IzvješćeRevizora} target="_blank" className="">
          <ul>
            <li>
              <p>{t("Izvješće revizora")}</p>
            </li>
          </ul>
        </a>
      </Row>

      <Row className="col-12">
        <a href={OdlukaNO} target="_blank" className="">
          <ul>
            <li>
              <p>{t("Odluka nadzornog odbora")}</p>
            </li>
          </ul>
        </a>
      </Row>

      <Row className="col-12">
        <a href={OdlukaNO2} target="_blank" className="">
          <ul>
            <li>
              <p>{t("Odluka nadzornog odbora 2")}</p>
            </li>
          </ul>
        </a>
      </Row>

      <Row className="col-12">
        <a href={OdlukaOUporabiDobiti} target="_blank" className="">
          <ul>
            <li>
              <p>{t("Odluka o uporabi dobiti")}</p>
            </li>
          </ul>
        </a>
      </Row>

      <Row className="col-12">
        <a
          href={OdlukaOUtvrđivanjuFinancijskihIzvješća}
          target="_blank"
          className=""
        >
          <ul>
            <li>
              <p>{t("Odluka o utvrđivanju financijskih izvješća")}</p>
            </li>
          </ul>
        </a>
      </Row>
    </>
  );
};

export default withTranslation()(PozivNaSkupštinuNews);
