import React from "react";
import { useParams } from "react-router";
import { Row } from "reactstrap";

import { isMobile } from "../../constants/constants";
import PozivNaSkupštinu from "../components/news/PozivNaSkupštinu";

const NewsSingle = () => {
  const { ID } = useParams();

  const newsComponent = () => {
    if (ID === "poziv-na-skupštinu") {
      return <PozivNaSkupštinu />;
    }

    return <h1 className="col-12">Pogrešan URL</h1>;
  };

  return (
    <div className={`${isMobile ? "" : "home-content-background"} height-full`}>
      <div
        className={`home-content height-full ${isMobile ? "width-full" : ""}`}
      >
        <Row className=" mb-2">{newsComponent()}</Row>
      </div>
    </div>
  );
};

export default NewsSingle;
