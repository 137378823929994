import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Navbar,
  NavbarBrand,
  NavLink,
  NavbarToggler,
  NavItem,
  //   UncontrolledDropdown,
  //   DropdownToggle,
  //   DropdownMenu,
  //   DropdownItem,
  Nav,
  //   NavbarText,
  Collapse,
} from "reactstrap";

import languages from "../../constants/languages";
import { isMobile } from "../../constants/constants";

const Topnav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{ position: "sticky", top: 0, zIndex: 10000 }}>
      <Navbar className="topnav" expand="md" light>
        <NavbarBrand href="/">
          <img src="/assets/img/logo/logo.png" style={{ maxHeight: "3rem" }} />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} style={{ border: "none" }} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/products">{t("Proizvodi i Usluge")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/references">{t("Reference")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/certificates">{t("Certifikati")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/jobs">{t("Zapošljavanje")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/eu-projects">{t("EU Projekti")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/info">{t("Informacije")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/contact">{t("Kontakt")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/news">{t("Novosti")}</NavLink>
            </NavItem>
            {isMobile && <div className="separator" />}
            {languages
              .filter((x) => x.key !== i18n.language)
              .map((language) => {
                if (
                  languages.filter((x) => x.key !== i18n.language).length > 1 &&
                  language.key === "en"
                )
                  return <div />;
                return (
                  <NavItem>
                    <NavLink
                      href="#"
                      onClick={() => i18n.changeLanguage(language.key)}
                      style={
                        isMobile ? {} : { position: "absolute", right: "10px" }
                      }
                    >
                      {language.name}
                    </NavLink>
                  </NavItem>
                );
              })}
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Option 1</DropdownItem>
                <DropdownItem>Option 2</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Reset</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Topnav;
